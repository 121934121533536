import render01 from "./1@Zoom.jpg"
import render02 from "./2@Zoom.jpg"
import render03 from "./3@Zoom.jpg"
import render04 from "./4@Zoom.jpg"
import render05 from "./5@Zoom.jpg"
import render06 from "./6@Zoom.jpg"
import render07 from "./7@Zoom.jpg"
import render08 from "./8@Zoom.jpg"
import render09 from "./9@Zoom.jpg"
import render10 from "./10@Zoom.jpg"
import render11 from "./11@Zoom.jpg"
import render12 from "./12@Zoom.jpg"
import render13 from "./13@Zoom.jpg"
import render14 from "./14@Zoom.jpg"
import render15 from "./15@Zoom.jpg"
import render16 from "./16@Zoom.jpg"
import render17 from "./17@Zoom.jpg"
import render18 from "./18@Zoom.jpg"
import render19 from "./19@Zoom.jpg"
import render20 from "./20@Zoom.jpg"
import render21 from "./21@Zoom.jpg"
import render22 from "./22@Zoom.jpg"
import render23 from "./23@Zoom.jpg"
import render24 from "./24@Zoom.jpg"
import render25 from "./25@Zoom.jpg"
import render26 from "./26@Zoom.jpg"
import render27 from "./27@Zoom.jpg"
import render28 from "./28@Zoom.jpg"
import render29 from "./29@Zoom.jpg"
const images = [
  {
    src: render01,
  },
  { src: render02 },
  { src: render03 },
  { src: render04 },
  { src: render05 },
  { src: render06 },
  { src: render07 },
  { src: render08 },
  { src: render09 },
  { src: render10 },
  { src: render11 },
  { src: render12 },
  { src: render13 },
  { src: render14 },
  { src: render15 },
  { src: render16 },
  { src: render17 },
  { src: render18 },
  { src: render19 },
  { src: render20 },
  { src: render21 },
  { src: render22 },
  { src: render23 },
  { src: render24 },
  { src: render25 },
  { src: render26 },
  { src: render27 },
  { src: render28 },
  { src: render29 },
]

export default { images }
