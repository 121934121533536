import React from "react"
import Burger from "../../images/burger.png"
import Cross from "../../images/Cross.svg"
import Header from "./Header.component.jsx"

class Sidenav extends React.Component {
  showSettings(event) {
    event.preventDefault()
  }

  constructor(props) {
    super(props)
    this.state = {
      positionIdx: 0,
      dimModeIdx: 0,
      isVisible: false,
      fluid: true,
      customAnimation: false,
      slow: false,
      size: 1,
      mode: "Wrapper-mb",
      img: Burger,
      pressed: false,
      MenuVisible: false,
    }
  }

  Changepath = () => {
    if (!this.state.pressed) {
      this.setState({ mode: "Wrapper-mb" })
      this.setState({ img: Cross })
      this.setState({ pressed: true })
      setTimeout(() => {
        this.setState({ MenuVisible: true })
      }, 300)
      document.getElementById("foo2").style.transition =
        "clip-path 1s ,height 1s"
      document.getElementById("foo2").style.clipPath =
        "polygon(0 0, 100% 0, 100% 100%, 0% 100%)"
      document.getElementById("foo2").style.height = "360px"
      document.getElementById("foo3").style.transition = "width,height 1s"
      document.getElementById("foo3").style.width = "30px"

      document.getElementById("foo3").style.height = "30px"
    } else {
      document.getElementById("foo2").style.height = "50px"
      document.getElementById("foo2").style.clipPath =
        " polygon(10% 0, 100% 0, 100% 100%, 0% 100%)"
      document.getElementById("foo3").style.width = "20px"

      document.getElementById("foo3").style.height = "15px"
      this.setState({ img: Burger })
      this.setState({ pressed: false })
      this.setState({ MenuVisible: false })
    }
  }

  render() {
    return (
      <div className="sidenavbar-mb">
        <Header />
        <div id="foo2" className="Wrapper-mb">
          {/* <img
            src={this.state.img}
            id="foo3"
            className="SidemenuButton-mb"
            onClick={this.Changepath}
          /> */}
          <a href="/">
            {" "}
            <div className="SidemenuHome-mb"> HOME </div>
          </a>
          {/* {this.state.MenuVisible &&
     (
     <nav className="Sidenav-mb">
        <a  className="nav-link-mb" activeClass="active" href="/#AboutUs"  >

     <div className="nav-mb-item">
About Us
</div>    
</a>
    <div class="vl2">
    </div>
        <a  className="nav-link-mb" activeClass="active" href="/#WhatWeDo"  >

      <div className="nav-mb-item">What We Do</div>
      </a>
          <div class="vl2">
    </div>
        <a  className="nav-link-mb" activeClass="active" href="/#HowWeDo" >

     <div className="nav-mb-item">How We Do</div>
     </a>
         <div class="vl2">
    </div>
        <a  className="nav-link-mb" activeClass="active" href="/#CaseStudies"  >

     <div className="nav-link-mb" className="nav-mb-item">Case Studies</div>
     </a>
         <div class="vl2">
    </div>
       <a  className="nav-link-mb" activeClass="active" href="/#Platform" >

     <div className="nav-mb-item">Platform</div>
     </a>
         <div class="vl2">
    </div>
                       <Link className="nav-link-mb" activeClass="active" to="ContactUs"  spy={true}>

          <div className="nav-mb-item">Contact Us</div>
</Link>


<a href="/gallery">

          <div className="nav-mb-item-last active">Gallery</div>
</a>
     </nav>
     )
     }

  */}
        </div>
      </div>
    )
  }
}
export default Sidenav
