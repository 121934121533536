import React, { useEffect, useState, useRef } from "react"
import GridLayout, { WidthProvider, Responsive } from "react-grid-layout"
import SEO from "../components/seo"
import render01 from "../images/Gallery/1@2x.jpg"
import render02 from "../images/Gallery/2@2x.jpg"
import render03 from "../images/Gallery/3@2x.jpg"
import render04 from "../images/Gallery/4@2x.jpg"
import render05 from "../images/Gallery/5@2x.jpg"
import render06 from "../images/Gallery/6@2x.jpg"
import render07 from "../images/Gallery/7@2x.jpg"
import render08 from "../images/Gallery/8@2x.jpg"
import render09 from "../images/Gallery/9@2x.jpg"
import render10 from "../images/Gallery/10@2x.jpg"
import render11 from "../images/Gallery/11@2x.jpg"
import render12 from "../images/Gallery/12@2x.jpg"
import render13 from "../images/Gallery/13@2x.jpg"
import render14 from "../images/Gallery/14@2x.jpg"
import render15 from "../images/Gallery/15@2x.jpg"
import render16 from "../images/Gallery/16@2x.jpg"
import render17 from "../images/Gallery/17@2x.jpg"
import render18 from "../images/Gallery/18@2x.jpg"
import render19 from "../images/Gallery/19@2x.jpg"
import render20 from "../images/Gallery/20@2x.jpg"
import render21 from "../images/Gallery/21@2x.jpg"
import render22 from "../images/Gallery/22@2x.jpg"
import render23 from "../images/Gallery/23@2x.jpg"
import render24 from "../images/Gallery/24@2x.jpg"
import render25 from "../images/Gallery/25@2x.jpg"
import render26 from "../images/Gallery/26@2x.jpg"
import render27 from "../images/Gallery/27@2x.jpg"
import render28 from "../images/Gallery/28@2x.jpg"
import render29 from "../images/Gallery/29@2x.jpg"
import images2 from "../images/Gallery/index.js"
import Sidenav from "../components/UI/Sidenav3.component"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import Sidenav_mb from "../components/UI/Sidenav_mb2.component"
import { LazyLoadImage } from "react-lazy-load-image-component"
import Lightbox from "react-image-lightbox"
import InfiniteScroll from "react-infinite-scroll-component"
import { Waypoint } from "react-waypoint"
import VisibilitySensor from "react-visibility-sensor"

const ResponsiveGridLayout = WidthProvider(Responsive)

const breakpointColumnsObj = {
  default: 4,
  1100: 3,
  700: 2,
  500: 1,
}

const GalleryCollection = () => {
  const myRef = useRef()
  const [photoIndex, setphotoIndex] = useState(0)
  const [isOpen, setisOpen] = useState(false)

  const OpenLightbox = i => {
    setphotoIndex(i)
    setisOpen(true)
  }
  const photos = [
    {
      src: render01,
      id: 0,
      key: "a",
    },
    {
      src: render02,
      id: 1,
      key: "b",
    },
    {
      src: render03,
      id: 2,
      key: "c",
    },
    {
      src: render04,
      id: 3,
      key: "d",
    },
    {
      src: render05,
      id: 4,
      key: "e",
    },
    {
      src: render06,
      id: 5,
      key: "f",
    },
    {
      src: render07,
      id: 6,
      key: "g",
    },
    {
      src: render08,
      id: 7,
      key: "h",
    },
    {
      src: render09,
      id: 8,
      key: "i",
    },
    {
      src: render10,
      id: 9,
      key: "j",
    },
    {
      src: render11,
      id: 10,
      key: "k",
    },
    {
      src: render12,
      id: 11,
      key: "l",
    },
    {
      src: render13,
      id: 12,
      key: "m",
    },
    {
      src: render14,
      id: 13,
      key: "o",
    },
    {
      src: render15,
      id: 14,
      key: "p",
    },
    {
      src: render16,
      id: 15,
      key: "q",
    },
    {
      src: render17,
      id: 16,
      key: "r",
    },
    {
      src: render18,
      id: 17,
      key: "s",
    },
    {
      src: render19,
      id: 18,
      key: "t",
    },
    {
      src: render20,
      id: 19,
      key: "u",
    },
    {
      src: render21,
      id: 20,
      key: "v",
    },
    {
      src: render22,
      id: 21,
      key: "w",
    },
    {
      src: render23,
      id: 22,
      key: "x",
    },
    {
      src: render24,
      id: 23,
      key: "y",
    },
    {
      src: render25,
      id: 24,
      key: "z",
    },
    {
      src: render26,
      id: 25,
      key: "27",
    },
    {
      src: render27,
      id: 26,
      key: "28",
    },
    {
      src: render28,
      id: 27,
      key: "29",
    },
    {
      src: render29,
      id: 28,
      key: "30",
    },
  ]

  const photos_all = []
  var layout1 = [
    { i: "a", x: 0, y: 0, w: 6, h: 4.5 },
    { i: "b", x: 6, y: 0, w: 6, h: 4.75 },
    { i: "c", x: 0, y: 4.5, w: 4, h: 4.6 },
    { i: "d", x: 0, y: 9.7, w: 4, h: 3.15 },
    { i: "e", x: 4, y: 4, w: 2, h: 7.75 }, //5
    { i: "f", x: 6, y: 4.5, w: 6, h: 4.5 },
    { i: "g", x: 6, y: 8.5, w: 3, h: 3 },
    { i: "h", x: 9, y: 8.5, w: 3, h: 3 },
    { i: "i", x: 0, y: 11, w: 12, h: 7.06 },
    { i: "j", x: 0, y: 18.5, w: 6, h: 4.75 },
    { i: "k", x: 6, y: 18.5, w: 6, h: 4.5 },
    { i: "l", x: 0, y: 23, w: 6, h: 4.5 },
    { i: "m", x: 0, y: 27.5, w: 6, h: 2.85 },
    { i: "o", x: 6, y: 23, w: 2.25, h: 7.6 },
    { i: "p", x: 10, y: 23, w: 3.75, h: 4.55 },
    { i: "q", x: 10, y: 26.9, w: 3.75, h: 3.05 },
    { i: "r", x: 0, y: 30.5, w: 8.25, h: 11.9 },
    { i: "s", x: 10, y: 30.5, w: 3.75, h: 4.9 },

    { i: "t", x: 10, y: 35.6, w: 3.75, h: 2.9 },

    { i: "u", x: 10, y: 38.5, w: 3.75, h: 4.1 },
    { i: "v", x: 0, y: 42.4, w: 12, h: 8 },
    { i: "w", x: 0, y: 50.4, w: 6, h: 4.5 },
    { i: "x", x: 6, y: 54.9, w: 6, h: 4.9 },
    { i: "y", x: 0, y: 50.4, w: 6, h: 4.9 },
    { i: "z", x: 6, y: 55.4, w: 6, h: 4.5 },
    { i: "27", x: 0, y: 61, w: 12, h: 7.1 },
    { i: "28", x: 0, y: 61, w: 3.6, h: 4.77 },

    { i: "29", x: 0, y: 68.1, w: 3.6, h: 7.34 },
    { i: "30", x: 3.6, y: 68.1, w: 8.4, h: 12.11 },
  ]
  var layout2 = [
    { i: "a", x: 0, y: 0, w: 6, h: 4.5 },
    { i: "b", x: 6, y: 0, w: 6, h: 4.75 },
    { i: "c", x: 0, y: 4.5, w: 4, h: 4.6 },
    { i: "d", x: 0, y: 9.7, w: 4, h: 3.15 },
    { i: "e", x: 4, y: 4, w: 2, h: 7.75 }, //5
    { i: "f", x: 6, y: 4.5, w: 6, h: 4.5 },
    { i: "g", x: 6, y: 8.5, w: 3, h: 3 },
    { i: "h", x: 9, y: 8.5, w: 3, h: 3 },
    { i: "i", x: 0, y: 11, w: 12, h: 7.06 },
    { i: "j", x: 0, y: 18.5, w: 6, h: 4.75 },
    { i: "k", x: 6, y: 18.5, w: 6, h: 4.5 },
    { i: "l", x: 0, y: 23, w: 6, h: 4.5 },
    { i: "m", x: 0, y: 27.5, w: 6, h: 2.85 },
    { i: "o", x: 6, y: 23, w: 2.25, h: 7.6 },
    { i: "p", x: 10, y: 23, w: 3.75, h: 4.55 },
    { i: "q", x: 10, y: 26.9, w: 3.75, h: 3.05 },
    { i: "r", x: 0, y: 30.5, w: 8.25, h: 11.9 },
    { i: "s", x: 10, y: 30.5, w: 3.75, h: 4.9 },

    { i: "t", x: 10, y: 35.6, w: 3.75, h: 2.9 },

    { i: "u", x: 10, y: 38.5, w: 3.75, h: 4.1 },
    { i: "v", x: 0, y: 42.4, w: 12, h: 8 },
    { i: "w", x: 0, y: 35, w: 6, h: 4.5 },
    { i: "x", x: 6, y: 35, w: 6, h: 4.9 },
    { i: "y", x: 0, y: 35, w: 6, h: 4.9 },
    { i: "z", x: 6, y: 35, w: 6, h: 4.5 },
    { i: "27", x: 0, y: 43.5, w: 12, h: 7.1 },
    { i: "28", x: 0, y: 43.5, w: 3.6, h: 4.77 },

    { i: "29", x: 0, y: 46.3, w: 3.6, h: 7.34 },
    { i: "30", x: 3.6, y: 43.5, w: 8.4, h: 12.11 },
  ]
  const [active, setActive] = useState(false)
  const [canload, setcanload] = useState(true)
  const [hasMore, setHasMore] = useState(true)

  const [sliceindex, setsliceindex] = useState(8)
  const [sliceindex_mb, setsliceindex_mb] = useState(4)
  const [photo2, setphoto2] = useState(photos.slice(0, 8))
  const [photo_mb, setphoto_mb] = useState(photos.slice(0, 4))

  const isBrowser = () => typeof window !== "undefined"
  const layoutchange = () => {}
  const [counter, setCounter] = useState(0)

  const checkthisshit = isVisible => {
    setsliceindex(sliceindex + 8)

    setphoto2(photos.slice(0, sliceindex))
  }

  const checkthisshit2 = isVisible => {
    if (isVisible) {
      setsliceindex_mb(sliceindex_mb + 4)
      setphoto_mb(photos.slice(0, sliceindex_mb))
    }
  }
  const [Height, setHeight] = useState(
    typeof window !== "undefined"
      ? setTimeout(() => {
          isBrowser() && testing2()
        }, 1500)
      : 144
  )
  const [layout, setLayout] = useState({ lg: layout1 })

  const testing2 = () => {
    setHeight((window.innerWidth - 220) / 12 - 8)
  }
  useEffect(() => {
    isBrowser && window.addEventListener("resize", testing2)
    isBrowser() && testing2()
    isBrowser() && setHeight((window.innerWidth - 220) / 12 - 8)
    setTimeout(() => {
      isBrowser() && testing2()
    }, 5000)
    console.log(sliceindex)
  }, [])

  return (
    <>
      <SEO title="Gallery" />

      <div>
        <Sidenav />
        <Sidenav_mb />
        {isOpen && (
          <Lightbox
            mainSrc={images2.images[photoIndex].src}
            nextSrc={images2.images[photoIndex].src}
            prevSrc={images2.images[photoIndex].src}
            onCloseRequest={() => setisOpen(false)}
            onMovePrevRequest={() =>
              setphotoIndex((photoIndex + photos.length - 1) % photos.length)
            }
            onMoveNextRequest={() =>
              setphotoIndex((photoIndex + 1) % photos.length)
            }
            clickOutsideToClose={true}
          />
        )}

        <div className="Gallery-Container">
          <div className="Gallery-font">GALLERY</div>
        </div>

        <div className="GalleryImages-Container">
          <ResponsiveGridLayout
            draggableCancel=".gallery1"
            autoSize={true}
            className="layout"
            isResizable={false}
            onLayoutChange={layoutchange}
            layouts={layout}
            rowHeight={Height}
            isDraggable={false}
            breakpoints={{ lg: 2560 }}
            cols={{ lg: 12 }}
          >
            {photo2.map((photo, i) => {
              return (
                <div
                  key={photo.key}
                  data-grid={layout1[i]}
                  onClick={() => {
                    OpenLightbox(photo.id)
                  }}
                >
                  <LazyLoadImage
                    effect="blur"
                    src={photo.src}
                    placeholderSrc={<h1>loading...</h1>}
                    placeholder={<h1>test</h1>}
                    height="100%"
                    onClick={() => OpenLightbox(photo.id)}
                    width="100%"
                    style={{ width: "100%", display: "block" }}
                    className="gallery1"
                  />
                </div>
              )
            })}
          </ResponsiveGridLayout>
          <VisibilitySensor onChange={checkthisshit} active={true}>
            <div className="visiblesensor"> </div>
          </VisibilitySensor>{" "}
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 1280: 3 }}
          >
            <Masonry columnsCount={1} gutter="10px" className="layout-mb">
              {photo_mb.map(photo => {
                return (
                  <LazyLoadImage
                    effect="blur"
                    src={photo.src}
                    placeholderSrc={<h1>loading...</h1>}
                    placeholder={<h1>test</h1>}
                    height="100%"
                    onClick={() => OpenLightbox(photo.id)}
                    width="100%"
                    style={{ width: "100%", display: "block" }}
                    className="Gallery-img-mb"
                  />
                )
              })}
              <VisibilitySensor onChange={checkthisshit2}>
                <div className="visiblesensor"> </div>
              </VisibilitySensor>{" "}
            </Masonry>
          </ResponsiveMasonry>
          {/*
          <div className="gallery-space"></div>
          <Masonry columnsCount={1} gutter="10px">
            {photos2.map(photo => {
              return (
                <LazyLoadImage
                  effect="blur"
                  src={photo.src}
                  placeholderSrc={<h1>loading...</h1>}
                  placeholder={<h1>test</h1>}
                  height="100%"
                  onClick={() => OpenLightbox(photo.id)}
                  width="100%"
                  style={{ width: "100%", display: "block" }}
                  className="Gallery-img"
                />
              )
            })}
            } >
          </Masonry>
          <div className="gallery-space"></div>
          <ResponsiveMasonry
            columnsCountBreakPoints={{ 350: 1, 750: 2, 1280: 3 }}
          >
            <Masonry gutter="10px">
              {photos3.map(photo => {
                return (
                  <LazyLoadImage
                    effect="blur"
                    src={photo.src}
                    placeholderSrc={<h1>loading...</h1>}
                    placeholder={<h1>test</h1>}
                    height="100%"
                    onClick={() => OpenLightbox(photo.id)}
                    width="100%"
                    style={{ width: "100%", display: "block" }}
                    className="Gallery-img"
                  />
                )
              })}
              } >
            </Masonry>
          </ResponsiveMasonry> */}
        </div>
      </div>
    </>
  )
}

export default GalleryCollection
